import React from 'react'
import Layout from '../../templates/Main'
import Account from '../../modules/Account'
import Heading from '../../modules/Heading'
import { Box, Flex } from 'rebass/styled-components'
import Logo from '../../assets/icons/brandmark-transparent.svg'

const ClientAccountPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='Account' />
            <Account type='client' />
            <Flex variant='container' bg='black' alignItems='flex-end'>
                <Flex alignItems='flex-end' height={['200px', '200px', '287px']} width='33%' sx={{ borderRight: '1px solid rgba(255,255,255,0.1)' }}>
                    <Box width='190px'>
                        <Logo />
                    </Box>
                </Flex>
                <Box height={['200px', '200px', '287px']} width='33%' sx={{ borderRight: '1px solid rgba(255,255,255,0.1)' }}/>
                <Box height={['200px', '200px', '287px']} width='33%'/>
            </Flex>
        </Layout>
    )
}

export default ClientAccountPage
